import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import barHorizontalBarChart from "assets/images/chart-images/horizontal-bar-chart.png";
import lineLineChart from "assets/images/chart-images/line-chart.png";
import boxChartImage from 'assets/images/chart-images/box-whisker.png';
import table from "assets/images/chart-images/table.png";
import lineSimpleBarWithCounts from "assets/images/chart-images/simple-bar-with-counts.png";

const timePeriodList = [
        // Time period - None
        { id: "TDFTP-L01s", name: "line", chartImage: lineLineChart, disabled: false , description: "AVERAGE" },
        { id: "TDFTP-L01e", name: "line", chartImage: lineLineChart, disabled: false, description: "AVERAGE" },
        { id: "TDFTP-L05e", name: "line", chartImage: lineLineChart, disabled: false, description: "MEDIAN" },
        { id: "TDFTP-BV05e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "MEDIAN" },
        { id: "TDFTP-BV01s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false , description: "AVERAGE"},
        { id: "TDFTP-BXV05s", name: "simple-bar", chartImage: boxChartImage, disabled: false, description: "MEDIAN" },
        { id: "TDFTP-BV05s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false , description: "MEDIAN"},
        { id: "TDFTP-L05s", name: "simple-bar", chartImage: lineLineChart, disabled: false , description: "MEDIAN"},
        { id: "TDFTP-T05s", name: "simple-bar", chartImage: table, v2: "Column", disabled: false , description: "MEDIAN"},
        { id: "TDFTP-T06s", name: "simple-bar", chartImage: table, v2: "Row", disabled: false , description: "MEDIAN"},
        { id: "TDFTP-T07s", name: "simple-bar", chartImage: table, v2: "Column", disabled: false , description: "MEDIAN • IQR"},
        { id: "TDFTP-T08s", name: "simple-bar", chartImage: table, v2: "Row", disabled: false , description: "MEDIAN • IQR"},
        { id: "TDFTP-T01s", name: "simple-bar", chartImage: table, v2: "Column", disabled: false , description: "AVERAGE"},
        { id: "TDFTP-T02s", name: "simple-bar", chartImage: table, v2: "Row", disabled: false , description: "AVERAGE"},
        { id: "TDFTP-T03s", name: "simple-bar", chartImage: table, v2: "Column", disabled: false , description: "AVERAGE • STD DEV"},
        { id: "TDFTP-T04s", name: "simple-bar", chartImage: table, v2: "Row", disabled: false , description: "AVERAGE • STD DEV"},
        { id: "TDFTP-BV01e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE" },
        { id: "TDFTP-BV02e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE" },
        { id: "TDFTP-BV03e", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "AVERAGE" },
        { id: "TDFTP-BV04e", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "AVERAGE" },
        { id: "TDFTP-BV06e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "MEDIAN" },
        { id: "TDFTP-BV07e", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "MEDIAN" },
        { id: "TDFTP-BV08e", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "MEDIAN" },
        { id: "TDFTP-BXV05e", name: "bar", chartImage: boxChartImage, disabled: false, description: "MEDIAN" },
        { id: "TDFTP-BH01s", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: false,description: "AVERAGE"  },
        { id: "TDFTP-BH01e", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: false, description: "AVERAGE" },
        { id: "TDFTP-T01e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
        { id: "TDFTP-T02e", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },
        { id: "TDFTP-T03e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE • STD DEV " },
        { id: "TDFTP-T05e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN" },
        { id: "TDFTP-T06e", name: "table", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN" },
        { id: "TDFTP-T07e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN • IQR" },
        { id: "TDFTP-T08e", name: "table", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN • IQR" },
        { id: "TDFTP-T04e", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE • STD DEV " },
        { id: "TDFTP-BV01r", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false , description: "AVERAGE"},
        { id: "TDFTP-BV04r", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false , description: "AVERAGE"},
        { id: "TDFTP-L01r", name: "line", chartImage: lineLineChart, disabled: false , description: "AVERAGE"},
        { id: "TDFTP-T03r", name: "table", chartImage: table, v2: "Column", disabled: false , description: "AVERAGE • STD DEV"},
        { id: "TDFTP-T04r", name: "table", chartImage: table, v2: "Row", disabled: false , description: "AVERAGE • STD DEV"},
        { id: "TDFTP-BV05r", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false , description: "MEDIAN"},
        { id: "TDFTP-BV08r", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false , description: "MEDIAN"},
        { id: "TDFTP-L05r", name: "line", chartImage: lineLineChart, disabled: false , description: "MEDIAN"},
        { id: "TDFTP-BXV05r", name: "box", chartImage: boxChartImage, disabled: false , description: "MEDIAN • IQR"},
        { id: "TDFTP-T07r", name: "table", chartImage: table, v2: "Column", disabled: false , description: "MEDIAN • IQR"},
        { id: "TDFTP-T08r", name: "table", chartImage: table, v2: "Row", disabled: false , description: "MEDIAN • IQR"},
        { id: "TDFTP-T20r", name: "table", chartImage: table, disabled: false , description: ""},

];

export default timePeriodList;
