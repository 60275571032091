import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
// import Intercom from "utils/intercom";

import PrivateRoute from './privateRoute';
import PublicRoute from './publicRoute';
import AcountsRoute from "./acountsRoute"
import SuperAdminRoute from './superAdminRoute';
import HelpCenterRoute from './helpCenterRoute';
import DemoLoadingPage from "views/public/demo-loading-page";
import LoginUsUrerPage from "views/public/login-us-user"
import LoginUsInviteUrerPage from "views/public/login-invite-us-user"
import Loading from "views/public/loading";
import Spinner from 'components/elements/spinner/Spinner';

const AutomationRegenerate = lazy(() => import('containers/super-admin/automationRegenerate'))
const DownloadReport = lazy(() => import('containers/download-report'))
const DashboardContainer = lazy(() => import('containers/super-admin/dashboard'));
const Subscribers = lazy(() => import('containers/super-admin/subscribers'));
const Individuals = lazy(() => import('containers/super-admin/individual'));
const Organization = lazy(() => import('containers/super-admin/organization'));
const Activity = lazy(() => import('containers/super-admin/activity'));
const DummyDataPage = lazy(() => import('containers/super-admin/dummy-data-page'));
const CouponsPage = lazy(() => import('containers/super-admin/coupons-page'));
const Support = lazy(() => import('containers/support'));
const Chart = lazy(() => import('containers/charts'));
const MyAccount = lazy(() => import('containers/my-account'));
const MyOrganization = lazy(() => import('containers/my-organization'));
const MyPreferences = lazy(() => import('containers/my-preferences'));
const Login = lazy(() => import('containers/public/login'));
const SignUp = lazy(() => import('containers/public/sign-up'));
const ChooseNewPassword = lazy(() => import('containers/public/sign-up'));
const ChangePassword = lazy(() => import('containers/public/choose-new-password'));
const ConfirmNewEmail = lazy(() => import('containers/public/confirm-new-email'));
const ConfirmInviteNewEmailContainer = lazy(()=> import("containers/public/confirm-invite-new-email"))
const ForgotInvitePassword  = lazy(()=> import("containers/public/forgot-invite-password"))
const ConfirmEmailAndLogin = lazy(() => import('containers/public/confirm-email-and-login'));
// const ConfirmEmailAndLogin = lazy(() => import('containers/public/confirm-email-and-login'));
const ForgotPassword = lazy(() => import('containers/public/forgot-password/index.js'));
// const LandingPageContainer = lazy(() => import('containers/public/landing-page-OLD'));
const LandingPageContainer = lazy(() => import('containers/public/landing-page'));
const MyFavoritesPage = lazy(() => import('containers/user/favorites'));
const TemplatesContainer = lazy(() => import('containers/user/reports'));
const MySubscription = lazy(() => import('containers/user/subscription'));
const MyAccountsPage = lazy(()=> import("containers/my-accounts-page"))
const ChangeOrganization = lazy(()=> import("containers/change-organization"))
//legals
const MyTeamPage = lazy(()=>import("views/my-team-page"))
const MyTeamPageAllReports = lazy(()=>import("views/my-team-page/all-reports"))
// const OrganizationSettings = lazy(()=>import("views/my-team-page/organization-settings"))

const TermsOfUse = lazy(() => import('views/legals/terms-of-use'));
const PrivacyPolicy = lazy(() => import('views/legals/privacy-policy'));
const CookiePolicy = lazy(() => import('views/legals/cookie-policy'));
const PdfPreviewContainer = lazy(() => import('containers/pdf-preview'));
const PdfImgPreviewContainer = lazy(() => import('containers/pdf-images-preview'));
const PNGPreviewContainer = lazy(() => import('containers/png-preview'));
const DirectDelivery = lazy(()=>import('views/super-admin/dashboard/delivery-stats'))
const GettingStarted = lazy(() => import('containers/public/help-center/getting-started'));
const FormattingCharts = lazy(() => import('containers/public/help-center/formatting-charts'));
const OriginReportsCharts = lazy(() => import('containers/public/help-center/origin-reports-charts'));
const UnderstandingData = lazy(() => import('containers/public/help-center/understanding-data'));
const BestPractices = lazy(() => import('containers/public/help-center/best-practices'));
const NewFeatures = lazy(() => import('containers/public/help-center/new-features'));
const ToolsAndFeatures = lazy(() => import('containers/public/help-center/tools-and-features'));

class Routes extends Component {

    // componentDidUpdate() {
    //     const {
    //         auth: {
    //             userData: { user }
    //         }
    //     } = this.props;

        // if (user) {
            // if (window.location.hostname.startsWith('admin')) { Intercom.hide() };
        //     if (user.role !== "super_admin") {
        //         Intercom.initUser(user);
        //     } else {
        //         Intercom.hide();
        //     }
        // } else {
        //     Intercom.initGuest();
        // }
    // }

    // componentDidMount() {
    //     Intercom.initGuest();
    // }

    render() {
        const {
            auth: {userData: { user: { accountType } = {}   }}} = this.props;
        return (
            <BrowserRouter>
                <Suspense fallback={<div style={{height: "100vh", display: "flex", alignItems: "center", justifyContent: "center"}}><Spinner size='big' /></div>}>
                    <Switch>
                        <AcountsRoute path="/my-accounts-page" component={MyAccountsPage}/>
                        <SuperAdminRoute path="/dashboard" component={DashboardContainer} />
                        <SuperAdminRoute path="/subscribers" component={Subscribers} />
                        <SuperAdminRoute path="/individuals/:id" component={Individuals} />
                        <SuperAdminRoute path="/organizations/:id" exact component={Organization} />
                        <SuperAdminRoute path="/activity" component={Activity} />
                        <SuperAdminRoute path="/dummy-datas" component={DummyDataPage} />
                        <SuperAdminRoute path="/direct-delivery" component={DirectDelivery} />
                        <SuperAdminRoute path="/coupons" component={CouponsPage} />
                        <SuperAdminRoute path="/regenerate-automation" component={AutomationRegenerate} />
                        <HelpCenterRoute path="/help-center/getting-started" component={GettingStarted} />
                        <HelpCenterRoute path="/help-center/formatting-charts" component={FormattingCharts} />
                        <HelpCenterRoute path="/help-center/origin-reports-charts" component={OriginReportsCharts} />
                        <HelpCenterRoute path="/help-center/understanding-data" component={UnderstandingData} />
                        <HelpCenterRoute path="/help-center/best-practices" component={BestPractices} />
                        <HelpCenterRoute path="/help-center/new-features" component={NewFeatures} />
                        <HelpCenterRoute path="/help-center/tools-and-features" component={ToolsAndFeatures} />
                        <PublicRoute path="/login" component={Login} />
                        {/* <PublicRoute path="/registration" component={SignUp} />  */}
                        <PublicRoute path="/confirm-email/:code" component={ChooseNewPassword} />
                        <PublicRoute path="/change-password/:code" component={ChangePassword} />
                        <PublicRoute path="/confirm-email-and-login/:code" component={ConfirmEmailAndLogin} />
                        <PublicRoute path="/confirm-invite-email-and-login/:code/:pass" component={ConfirmInviteNewEmailContainer} />
                        <PublicRoute path="/forgot-invite-password/:code" component={ForgotInvitePassword} />
                        <PublicRoute path="/confirm-new-email/:code" component={ConfirmNewEmail} />
                        <PublicRoute path="/forgot-password" component={ForgotPassword} />
                        <PublicRoute path="/terms-of-use" component={TermsOfUse} />
                        <PublicRoute path="/privacy-policy" component={PrivacyPolicy} />
                        <PublicRoute path="/cookie-policy" component={CookiePolicy} />
                        <PublicRoute hideFooter path="/pdfimgpreviewcontainer/:id" component={PdfImgPreviewContainer} />
                        <PublicRoute hideFooter path="/pdfpreviewcontainer/:id" component={PdfPreviewContainer} />
                        <PublicRoute hideFooter path="/pngpreviewcontainer/:id" component={PNGPreviewContainer} />
                        <Route path="/support" render={(props) => <Support auth={this.props.auth} {...props} />} />
                        <PublicRoute path="/demo/loading" component={DemoLoadingPage} />
                        <PublicRoute path="/log-us-user/:token" component={LoginUsUrerPage} />
                        <PublicRoute path="/log-us-invite-user/:token/:invite/:loginAsInviteUserValue" component={LoginUsInviteUrerPage} />
                        <PrivateRoute title='My Team' subtitle="Visualize your data and customize your charts" path="/myteam" component={MyTeamPage} />
                        <PrivateRoute title='MyTeam all reports' subtitle="Visualize your data and customize your charts" path="/myteam-all-reports" component={MyTeamPageAllReports} />
                        {/* <PrivateRoute title='MyTeam all reports' subtitle="Visualize your data and customize your charts" path="/organization-settings" component={OrganizationSettings} /> */}
                        <PrivateRoute title='MyCharts' subtitle="Visualize your data and customize your charts" path="/mycharts/:reportType" component={MyFavoritesPage} />
                        <PrivateRoute title='MyCharts' subtitle="Visualize your data and customize your charts" path="/demo/mycharts/:reportType" component={MyFavoritesPage} />
                        <PrivateRoute  path="/change-organization" component={ChangeOrganization}/>



                        <PrivateRoute title='MyReports' reports subtitle="Visualize your data and customize your charts" path="/reports/:type/:id" component={TemplatesContainer} />
                        <PrivateRoute title='MyReports' reports subtitle="Visualize your data and customize your charts" path="/reports/empty-page" component={TemplatesContainer} />

                        <PrivateRoute title='MyReports' reports subtitle="Visualize your data and customize your charts" path="/demo/reports/:type/:id" component={TemplatesContainer} />
                        <PrivateRoute title='MyReports' reports subtitle="Visualize your data and customize your charts" path="/demo/reports" component={TemplatesContainer} />

                        <PrivateRoute title='MyCharts' path="/favorites/:reportType" component={MyFavoritesPage} />
                        <PrivateRoute exact title='Charts & tables' path="/set-password" component={ChooseNewPassword} />

                        <PrivateRoute title='Charts & tables' subtitle="Visualize your data and customize your charts" path="/demo/charts-tables" component={Chart} />
                        <PrivateRoute title='Charts & tables' subtitle="Visualize your data and customize your charts" path="/charts-tables" component={Chart} />
                        <PrivateRoute title='Charts & tables' path="/edit" component={Chart} />
                        <PrivateRoute title='Charts & tables' path="/demo/edit" component={Chart} />


                        <PrivateRoute title='My Account' path="/my-account" component={MyAccount} />
                        <PrivateRoute title='My Organization' path="/my-organization" component={MyOrganization} />
                        <PublicRoute path="/report-download/" component={DownloadReport} />


                        <PrivateRoute title='My Preferences' subtitle="Visualize your data and customize your charts" path="/my-preferences" component={MyPreferences} />
                        {accountType !== "unlimited" && (
                            <PrivateRoute title='My Subscription' path="/subscription" component={MySubscription} />
                        )}
                        <PublicRoute path="/" component={LandingPageContainer} />
                        <Redirect exact from="/mycharts" to="/mycharts/create-chart" />

                    </Switch>
                </Suspense>
            </BrowserRouter>
        );
    } mycharts
};

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
};

export default connect(mapStateToProps)(Routes);